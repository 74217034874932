/* You can add global styles to this file, and also import other style files */
@import "theme";


// Include non-theme styles for core.
@include mat-core($config);

// Include all theme styles for the components.
@include angular-material-theme($theme);

.mat-secondary {
  color: $secondary-color;
}
.mat-button.mat-secondary {
  color: $secondary-color;
}
body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
